<script>
/*globals google */
import * as Sentry from '@sentry/vue'
import axiosApiClient from '../api/axiosApiClient'
import jsonwebtoken from 'jsonwebtoken'
import log from '@/lib/utils/log'
import { initRolePermissionList } from '../permission/rolePermission'
import { featureFlags } from '@/config'

export default {
	name: 'GoogleSignIn',
	data() {
		return {
			env: process.env.VUE_APP_ENV,
			featureFlags
		}
	},
	mounted() {
		google.accounts.id.initialize({
			client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
			callback: this.onSuccess
		})
		google.accounts.id.renderButton(
			document.getElementById('google-login-button'),
			{ theme: 'outline', size: 'large' } // customization attributes
		)
		// google.accounts.id.prompt() // also display the One Tap dialog
	},
	methods: {
		async onSuccess(googleUser) {
			const { credential: gToken } = googleUser
			try {
				const response = await axiosApiClient({
					method: 'POST',
					url: '/login/google',
					data: { token: gToken }
				})
				const { token } = response.data
				if (!token) {
					await google.accounts.id.disableAutoSelect()
					alert('Login denied!')
					return
				}
				// Check if has valid role
				const parsedToken =
					jsonwebtoken.decode(token, { complete: true }) || {}
				const roles = (parsedToken.payload || {}).aud || []
				if (!['ADM', 'OPS'].some(i => roles.includes(i))) {
					throw new Error('Unauthorized!')
				}
				axiosApiClient.defaults.headers.common.Authorization = `Bearer ${token}`
				localStorage.setItem('token', token)
				localStorage.setItem('gToken', gToken)

				localStorage.setItem('roles', JSON.stringify(roles))
				await initRolePermissionList()

				const email =
					this.env === 'ldev'
						? 'backoffice.user@email.com'
						: jsonwebtoken.decode(gToken).email // let it crash if payload is not correct

				this.$root.email = email
				localStorage.setItem('email', email)

				Sentry.setUser({
					id: parsedToken.payload.sub,
					email
				})
				this.$router.push('/')
			} catch (e) {
				await google.accounts.id.disableAutoSelect()
				alert('Login denied!')
				log.error('Login denied', null, e.stack)
			}
		},
		async login() {
			this.onSuccess({
				credential: 'GOOGLE_TOKEN_STUB'
			})
		}
	}
}
</script>

<template>
	<div id="google">
		<div id="signin">
			<p>Authenticate using @lalamove.com</p>

			<div id="google-login-button"></div>
		</div>
		<button v-if="env === 'ldev'" id="bypass-google" @click="login">
			Login
		</button>
	</div>
</template>

<style lang="scss">
#google {
	text-align: center;
	margin: auto;
	position: fixed;
	padding: 0;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #f0f0f0;
}
#signin {
	display: block;
	margin: 20px auto;
	max-width: 400px;
	background: #fff;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 30px;
}
#g-signin2 {
	height: 20px;
	width: 100px;
	margin: 1px -60px;
	position: relative;
	top: 50%;
	left: 50%;
}

#google-login-button {
	display: inline-block;
	iframe {
		margin: 0 !important;
	}
}
</style>
