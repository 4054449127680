<template>
	<div id="app" style="display: flex; overflow: hidden">
		<side-nav />
		<div id="header">
			<img alt="Lalamove logo" src="./assets/logo.png" />
			<h1>Welcome to Lalamove+ Back Office Panel</h1>
		</div>
	</div>
</template>

<script>
import SideNav from './components/SideNav.vue'

export default {
	name: 'App',
	components: { SideNav }
}
</script>
